"use client"

import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider"
import clsx from "clsx"
import { signIn } from "next-auth/react"
import { useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { getOrganizationPagePath } from "app/auth/_utils"
import { JoinUsInfo } from "app/auth/sign-in/_components"
import { getProjects } from "app/auth/sign-in/_components/SignInForm/actions.server"
import { Sections } from "app/auth/sign-in/_types/types"
import { ChallengeResponseDetails } from "app/auth/sign-in/page"
import ErrorBlock from "components/Errors/ErrorBlock"
import Link from "next/link"
import { useRouter, useSearchParams } from "next/navigation"
import Loader from "src/components/Loader/Loader"
import { Button } from "src/orca/components/Button/Button"
import { Field } from "src/orca/components/Field/field"
import { Input } from "src/orca/components/Input/input"
import { PasswordInput } from "src/orca/components/PasswordInput/PasswordInput"
import { isErrorResponse } from "src/utils/utils.shared"

type SignInFormProps = {
  setChallengeResponseDetails(value: ChallengeResponseDetails): void
  setSection(section: Sections): void
  challengeResponseDetails?: ChallengeResponseDetails
}

const signInFormContent = {
  title: "Welcome to ProcessOut",
  description: "Log in by using the temporary password we sent you by email.",
  passwordLabel: "Temporary password",
  passwordPlaceholder: "Enter temporary password",
  submitButtonText: "Get started",
}

const newUserSignInFormContent = {
  title: "Log In",
  description: "",
  passwordLabel: "Password",
  passwordPlaceholder: "Your password",
  submitButtonText: "Log in",
}

export default function SignInForm({
  challengeResponseDetails,
  setChallengeResponseDetails,
  setSection,
}: SignInFormProps) {
  const router = useRouter()
  const [error, setError] = useState<string>()
  const searchParams = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const isNewUser = searchParams?.get("newUser") === "true"
  const callbackUrl = searchParams?.get("callbackUrl")

  const { title, description, passwordLabel, passwordPlaceholder, submitButtonText } = useMemo(
    () => (isNewUser ? signInFormContent : newUserSignInFormContent),
    [isNewUser],
  )

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      email: challengeResponseDetails?.email ?? "",
      password: "",
    },
  })

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setIsLoading(true)

    const lowerCaseEmail = email.toLowerCase()
    const response = await signIn("cognito-sign-in", {
      email: lowerCaseEmail,
      password,
      redirect: false,
    })

    if (!response) {
      setIsLoading(false)
      setError("An unexpected error occurred!")
      return
    }

    if (!response.ok && response.error) {
      setIsLoading(false)
      const parsedError = JSON.parse(response.error)

      switch (parsedError.message) {
        case ChallengeNameType.NEW_PASSWORD_REQUIRED: {
          setSection(Sections.NEW_PASSWORD_REQUIRED)
          setChallengeResponseDetails(parsedError)
          return
        }
        case ChallengeNameType.SOFTWARE_TOKEN_MFA: {
          setChallengeResponseDetails(parsedError)
          setSection(Sections.TWO_FACTOR_AUTHENTICATION)
          return
        }
        default: {
          setError(parsedError.message)
          return
        }
      }
    }

    if (callbackUrl) {
      const url = decodeURIComponent(callbackUrl)
      if (url.startsWith("/") || url.startsWith(window.location.origin)) {
        return router.push(url)
      }
    }

    const organizationPath = await getOrganizationPagePath()

    if (!organizationPath) {
      setError(
        "We encountered an issue retrieving your organization information. Please try again.",
      )
      setIsLoading(false)
      return
    }

    try {
      const projects = await getProjects()

      if (isErrorResponse(projects)) {
        if (projects.error.code === "error.redirect-error") {
          return router.push(projects.error.message)
        }

        return router.push(organizationPath)
      }

      if (projects.length > 1) {
        return router.push(organizationPath)
      }

      const project = projects[0]
      const url = project.production
        ? `/projects/${project.structureId}`
        : `/projects/test-${project.structureId}`
      return router.push(url)
    } catch (err) {
      setError("We encountered an issue retrieving your projects information. Please try again.")
      setIsLoading(false)
      return
    }
  })

  return (
    <>
      <ErrorBlock
        showError={!!error}
        text={error ?? "An unexpected error occurred!"}
        className={clsx({ "mb-4": !!error })}
      />
      <div className="mb-10">
        <h1 className="headline mb-2.5">{title}</h1>
        {description && <span className="text-text-tertiary">{description}</span>}
      </div>

      <form className="flex flex-col gap-y-5 relative" onSubmit={onSubmit}>
        {isLoading && (
          <div className="absolute w-full h-full flex justify-center items-center z-10 bg-surface-0 bg-opacity-50">
            <Loader />
          </div>
        )}

        <Controller
          control={control}
          name="email"
          rules={{ required: "Email is required" }}
          render={({ field, fieldState }) => (
            <Field id={field.name} label="Email" error={fieldState.error?.message} required>
              <Input
                {...field}
                type="email"
                placeholder="Your email address"
                autoComplete="username"
                invalid={Boolean(fieldState.error)}
                autoFocus
              />
            </Field>
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{ required: "Password is required" }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col gap-tokens-space-8">
              <Field
                id={field.name}
                label={passwordLabel}
                error={fieldState.error?.message}
                required
              >
                <PasswordInput
                  {...field}
                  placeholder={passwordPlaceholder}
                  autoComplete="current-password"
                  invalid={Boolean(fieldState.error)}
                  autoFocus
                />
              </Field>
              {!isNewUser && (
                <Link
                  className="ml-tokens-space-4 font-medium"
                  href={`/auth/reset-password?email=${encodeURIComponent(watch("email"))}`}
                >
                  Forgot password?
                </Link>
              )}
            </div>
          )}
        />
        <Button type="submit">{submitButtonText}</Button>
      </form>
      <JoinUsInfo />
    </>
  )
}
